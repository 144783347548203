import { Repository } from './utils/Repository';
import { Pusher } from './utils/Pusher';

// -----------------------------------------------------
// Variables globales
// -----------------------------------------------------

window.dataLayer = window.dataLayer || [];

// -----------------------------------------------------
// Variables locales
// -----------------------------------------------------

const pusher = new Pusher();
const repository = new Repository();

// -----------------------------------------------------
// Funciones globales
// -----------------------------------------------------

window.dataLayerPushDeferred = (object) => {
    const promotionFlag = repository.getPromotionFlag();

    if (
        promotionFlag
        && object.hasOwnProperty('event')
        && ['view_item_list', 'view_item'].includes(object.event)
    ) {
        repository.addItemsToPromotion(object.ecommerce.items, promotionFlag.code);
    }

    if (
        object.hasOwnProperty('event')
        && object.hasOwnProperty('ecommerce')
        && object.ecommerce.hasOwnProperty('items')
        && [
            'select_item',
            'view_item_list',
            'view_item',
            'add_to_cart',
            'remove_from_cart',
            'view_cart',
            'begin_checkout',
            'add_shipping_info',
            'add_payment_info',
            'purchase',
        ].includes(object.event)
    ) {
        const itemsClicked = repository.getItemsClicked();
        const promotionItems = repository.getPromotionsItemsAssociation();

        object.ecommerce.items.forEach((item) => {
            //---------------------------------------------------------------------------
            // Buscar en localStorage si el ítem ha sido clicado anteriormente
            // y asociar el "item_list_name" y el "index" al ítem
            //---------------------------------------------------------------------------

            if (itemsClicked[item.item_name]) {
                item.item_list_name = itemsClicked[item.item_name].itemListName;
                item.index = itemsClicked[item.item_name].index;
            }

            //---------------------------------------------------------------------------
            // Buscar en localStorage si el ítem está asociado a una promoción
            // y asociar el "promotion_name" al ítem
            //---------------------------------------------------------------------------

            if (
                promotionItems[item.item_name]
                && (
                    itemsClicked === null
                    || !itemsClicked.hasOwnProperty(item.item_name)
                    || itemsClicked[item.item_name].createdAt > promotionItems[item.item_name].createdAt
                )
            ) {
                item.promotion_name = promotionItems[item.item_name].promotionName;
            }
        });
    }

    pusher.push(object);
};
