import { DATA_LAYER_POLLING_INTERVAL } from '../constants';

export class Pusher {
    constructor() {
        this.objects = [];
        this.lastPushTime = 0;
    }
    push(object) {
        this.objects.push(object);
        this.pushNext();
    }
    pushNext() {
        if (this.objects.length === 0) {
            return;
        }

        const now = Date.now();
        const timeSinceLastPush = now - this.lastPushTime;

        if (timeSinceLastPush >= DATA_LAYER_POLLING_INTERVAL) {
            this.lastPushTime = now;
            window.dataLayer.push(this.objects.shift());
        } else {
            const timeToPush = DATA_LAYER_POLLING_INTERVAL - timeSinceLastPush;
            setTimeout(() => {
                this.pushNext();
            }, timeToPush);
        }
    }
}
